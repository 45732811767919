<template>
  <div class="host-sw-area">
    <div class="swiper-container host-sw" id="hostSw">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(item, index) in hostPerList"
          :key="index"
        >
          <router-link
            :to="{
              name: 'HostList',
              params: { id: item.hostId },
              query: { name: item.name },
            }"
          >
            <div class="host-sw-img">
              <img :src="item.img" alt="" />
              <!-- <img :src="require('@/assets/img/' + item.img)" alt="" /> -->
            </div>
            <div class="host-sw-tit">{{ item.name }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

export default {
  name: "HostSwList",
  props: ["hostPerList", "pageIndex", "pageTotal"],
  data() {
    return {
      hostSw: "",
    };
  },
  mounted() {
    this.hostSw = new Swiper("#hostSw", {
      slidesPerView: 4.5,
      spaceBetween: 15,
      // freeMode: true,
      // on: {
      //   如果寫在這裡會有this的問題
      //   slideChange() {
      //     var actIndex = this.activeIndex;
      //     console.log(actIndex);
      //     this.$bus.$emit("hostPageData", this.pageIndex + 1);
      //     // foodName(actIndex);
      //   },
      //   // slideChangeTransitionEnd(){
      //   //   console.log(End);
      //   // }
      // },
    });
    this.hostSw.on("slideChange", () => {
      // console.log("slide changed");
      // // console.log(this.hostSw.activeIndex);
      // console.log(this.hostSw.isEnd);
      // console.log(this.pageIndex);
      // console.log(this.pageTotal);
      //因為外層 改 當前頁 跟 總頁數長度 做判斷，所以第一次一定都是0 ，每讀一次增加頁就會加一
      if (
        this.hostSw.isEnd &&
        this.pageIndex != this.pageTotal &&
        this.pageTotal != 1
      ) {
        this.$bus.$emit("hostPageData", this.pageIndex);
        // this.$bus.$emit("hostPageData", this.pageIndex + 1);
      }
    });
  },
  methods: {
    // pageLoad() {
    //   if (this.pageIndex != this.pageTotal) {
    //     console.log("==?????==");
    //     // this.$bus.$emit("hostPageData", this.pageIndex + 1);
    //   }
    // },
  },
  updated() {
    this.hostSw.update();
  },
};
</script>