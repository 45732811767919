<template>
  <div class="host-box">
    <div class="host-img" :class="swIsShow ? 'act' : ''">
      <img :src="hostImg" alt="" />
      <!-- <img :src="require('@/assets/img/' + hostImg)" alt="" /> -->
    </div>
    <div class="host-cont">
      <div class="host-info">
        <div class="tit">{{ tit }}</div>
        <div class="cont">
          <!-- <div>{{ day }}</div> -->
          <div>主持人：{{ hostName }}</div>
          <div>播出時間：<span v-html="time"></span></div>
        </div>
      </div>
      <div class="host-icon-wrap" style="display: none">
        <div class="host-num">{{ hostNum }}</div>
        <div class="radio-play-btn-box">
          <div class="radio-play-open radioPlayOpen">
            <img src="@/assets/img/icon_radio_plus.svg" alt="" />
          </div>
          <div class="radio-play-btn radio-play01">
            <img src="@/assets/img/radio_heart.svg" alt="" />
          </div>
          <div class="radio-play-btn radio-play02">
            <img src="@/assets/img/radio_play.svg" alt="" />
          </div>
          <div class="radio-play-btn radio-play03 videoOpen" @click="openVideo">
            <img src="@/assets/img/radio_tv.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="host-radio-area" v-show="swIsShow">
        <HostBoxSw
          :ind="ind"
          :hostRadioSwName="hostRadioSwName"
          :swList="swList"
          v-if="swList.length"
        />
        <!-- <div
          class="swiper-container host-radio-sw"
          :id="hostRadioSwName + (ind + 1)"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in swList"
              :key="index"
            >
              <div class="radio-cube-box">
                <div class="radio-cube-img">
                  <img :src="require('@/assets/img/' + item.img)" alt="" />
                </div>
                <div class="radio-cube-cont">
                  <div class="radio-cube-tit">
                    <div class="tit">{{ item.tit }}</div>
                    <div class="icon-box">
                      <div class="icon">
                        <img src="@/assets/img/icon_thumb_b.svg" alt="" />
                      </div>
                      <div class="icon">
                        <img src="@/assets/img/icon_heart_r.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="radio-cube-txt">
                    <div>主持人：{{ item.hostName }}</div>
                    <div>播出時間：{{ item.time }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </transition>
    <div class="host-w-icon-box">
      <div
        class="icon-w-arrow openHostRadio"
        :class="swIsShow ? 'act' : ''"
        @click="openEpisodeBtn(radioId)"
      ></div>
      <!-- @click="swIsShowStatus = !swIsShowStatus" -->
      <!-- <div class="icon-w icon-w-thumb"></div> -->
      <!-- <div class="icon-w icon-w-heart"></div> -->
      <!-- <div class="icon-w icon-w-fb"></div> -->
    </div>
  </div>
</template>
<script>
import HostBoxSw from "@/components/HostBoxSw.vue";

export default {
  name: "HostBox",
  props: [
    "ind",
    "radioId",
    "hostImg",
    "tit",
    "day",
    "hostName",
    "time",
    "hostNum",
    "hostRadioSwName",
    "swIsShow",
    "swList",
    "isMoreHost",
  ],
  methods: {
    //=== 點選 ===
    //== 傳送項目 ==
    openVideo() {
      this.$bus.$emit("swOpenVideo", this.tit);
    },
    //=== 去取得節目集數列表 ===
    openEpisodeBtn(id) {
      this.swIsShowStatus = !this.swIsShowStatus;
      console.log(id);
      var sentData = {
        programId: id,
        moreInd: this.isMoreHost,
      };
      this.$bus.$emit("toRadioEpisodeId", sentData);
      // this.$bus.$emit("toRadioEpisodeId", id);
    },
  },
  computed: {
    swIsShowStatus: {
      get() {
        return this.swIsShow;
      },
      set(val) {
        this.$emit("update:swIsShow", val);
      },
    },
  },
  components: {
    HostBoxSw,
  },
};
</script>
<style>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: ease-in; */
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  /* transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
}
.slide-enter-to,
.slide-leave {
  max-height: 202px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>