<template>
  <div class="adm-wrap">
    <div class="swiper-container adm-swiper" id="admSwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, i) in admList" :key="i">
          <a :href="item.url" class="adm-box" target="_blank" v-if="item.url">
            <div class="adm-img">
              <img :src="item.img" alt="" />
            </div>
            <div class="adm-tit">{{ item.Title }}</div>
          </a>
          <div class="adm-box" v-if="!item.url">
            <div class="adm-img">
              <img :src="item.img" alt="" />
            </div>
            <div class="adm-tit">{{ item.Title }}</div>
          </div>
          <!-- <div class="adm-img" @click="item.url ? urlLinkOut(item.url) : ''">
            <img :src="item.img" alt="" />
            <img :src="require('@/assets/img/' + item)" alt="" />
          </div>
          <div class="adm-tit">{{ item.Title }}</div> -->
        </div>
        <!-- <div class="swiper-slide">
          <div class="adm-img">
            <img src="@/assets/img/adm02.jpg" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import {
  apiGetAdmCategorys,
  apiGetAdmList,
  apiBannerBottom,
} from "@/api/api.js";

export default {
  name: "AdmArea",
  props: ["admType"],
  data() {
    return {
      admSwiper: "",
      actAdmType: "",
      admList: [
        // { img: "adm01.jpg", url: "" },
        // { img: "adm01.jpg", url: "" },
        // { img: "adm01.jpg", url: "" },
      ],
    };
  },
  mounted() {
    //=== 取得廣告 ===
    //live - 主頁 , 廣播 - Alian廣播 , 新聞 - 原視新聞 , 節目 - 原視節目
    // this.getAdmCategorys();
    //21050511562505687  = live , 21050311314042388 = 廣播 , 21050511592564961 = 新聞
    if (this.admType == "主頁") {
      this.actAdmType = "21050511562505687";
    } else if (this.admType == "Alian廣播") {
      this.actAdmType = "21050514054805657";
    } else if (this.admType == "原視新聞") {
      this.actAdmType = "21051811232206733";
    }
    this.getBannerBottom();
    //=== admSwiper ===
    this.admSwiper = new Swiper("#admSwiper", {
      slidesPerView: 1.4,
      // slidesPerView: "auto",
      spaceBetween: 20,
      freeMode: true,
    });
  },
  methods: {
    //=== 取得廣告分類 ===
    getAdmCategorys() {
      apiGetAdmCategorys()
        .then((result) => {
          console.log("廣告類型");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            //=== 取得廣告列表 ===
            var ind = resData.findIndex((item) => {
              return item.Title == this.admType;
            });
            var admListId = resData[ind].ID.toString();
            //== 取得廣告列表 ==
            this.getAdmList(admListId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取的廣告列表 ===
    getAdmList(id) {
      apiGetAdmList({ ID: id })
        .then((result) => {
          console.log("廣告列表");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            var admGetList = [];
            this.$.each(resData, (ind, val) => {
              var { Title, Icon, Link } = val;
              admGetList.push({ Title, img: Icon.ImgUrl, url: Link });
            });
            this.admList = admGetList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 後台設定廣告 ===
    getBannerBottom() {
      apiBannerBottom({ params: { menu_type: this.actAdmType } })
        .then((result) => {
          console.log("== 後台廣告列表 ==");
          // console.log(result.data);
          var resStatuse = result.data.status;
          if (resStatuse == true) {
            var resData = result.data.data;
            console.log(resData);
            var admGetList = [];
            this.$.each(resData, (ind, val) => {
              var { title, img_url, link } = val;
              admGetList.push({ Title: title, img: img_url, url: link });
            });
            this.admList = admGetList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 外連網址 ===
    urlLinkOut(href) {
      window.open(href, "_blank");
    },
  },
  updated() {
    this.admSwiper.update();
    this.admSwiper.slideTo(0, 0);
  },
};
</script>
<style>
.adm-swiper .adm-box {
  width: 100%;
}
</style>