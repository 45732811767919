<template>
  <div>
    <RadioLightBox />
    <NavTop />
    <NavBar />
    <NavSide />
    <div class="m-wrap-bg"></div>
    <div class="m-wrap">
      <div class="page-tit">ALIAN RADIO STATION</div>
      <div class="page-area">
        <HostSwList
          :hostPerList="hostPerList"
          :pageIndex="hostPage"
          :pageTotal="hostPageTotal"
          v-if="false"
        />
        <AdmArea admType="Alian廣播" />
        <!-- <div class="main-host">
          <div class="main-host-img">
            <img :src="mainHost.hostImg" alt="" />
          </div>
          <div class="main-host-name">主持人：{{ mainHost.name }}</div>
        </div> -->
        <div class="host-list">
          <div
            class="no-data"
            v-if="!hostAreaList.length && !hostAreaMoreList.length"
          >
            Loading...
          </div>
          <!-- :hostImg="item.hostImg" -->
          <HostBox
            v-for="(item, index) in hostAreaList"
            :key="index"
            :ind="index"
            :radioId="item.radioId"
            :hostImg="mainHost.hostImg"
            :tit="item.tit"
            :day="item.day"
            :hostName="item.hostName"
            :time="item.time"
            :hostNum="item.hostNum"
            :hostRadioSwName="hostRadioSwName"
            :swIsShow.sync="item.swIsShow"
            :swList="item.swList"
          />
          <HostBox
            v-for="(item, index) in hostAreaMoreList"
            :key="'hostMore' + index"
            :ind="index"
            :radioId="item.radioId"
            :hostImg="item.hostImg"
            :tit="item.tit"
            :day="item.day"
            :hostName="item.hostName"
            :time="item.time"
            :hostNum="item.hostNum"
            :hostRadioSwName="hostRadioSwNameMore"
            :swIsShow.sync="item.swIsShow"
            :swList="item.swList"
            :isMoreHost="item.sortId"
          />
          <!-- <div class="host-box">
            <div class="host-img"><img src="img/host_b01.png" alt="" /></div>
            <div class="host-cont">
              <div class="host-info">
                <div class="tit">職人藝事變</div>
                <div class="cont">
                  <div>2021/3/10</div>
                  <div>主持人：Gerger格格兒</div>
                  <div>播出時間：2400-0100</div>
                </div>
              </div>
              <div class="host-icon-wrap">
                <div class="host-num">123</div>
                <div class="radio-play-btn-box">
                  <div class="radio-play-open radioPlayOpen">
                    <img src="img/icon_radio_plus.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play01">
                    <img src="img/radio_heart.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play02">
                    <img src="img/radio_play.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play03 videoOpen">
                    <img src="img/radio_tv.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="host-radio-area">
              <div class="swiper-container host-radio-sw" id="hostRadioSw01">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio01.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂1</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio02.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂2</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio03.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂3</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio04.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂4</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio05.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂5</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="host-w-icon-box">
              <div class="icon-w-arrow openHostRadio"></div>
              <div class="icon-w icon-w-thumb"></div>
              <div class="icon-w icon-w-heart"></div>
              <div class="icon-w icon-w-fb"></div>
            </div>
          </div> -->
          <!-- <div class="host-box">
            <div class="host-img"><img src="img/host_b02.png" alt="" /></div>
            <div class="host-cont">
              <div class="host-info">
                <div class="tit">職人藝事變</div>
                <div class="cont">
                  <div>2021/3/10</div>
                  <div>主持人：Gerger格格兒</div>
                  <div>播出時間：2400-0100</div>
                </div>
              </div>
              <div class="host-icon-wrap">
                <div class="host-num">123</div>
                <div class="radio-play-btn-box">
                  <div class="radio-play-open radioPlayOpen">
                    <img src="img/icon_radio_plus.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play01">
                    <img src="img/radio_heart.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play02">
                    <img src="img/radio_play.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play03 videoOpen">
                    <img src="img/radio_tv.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="host-radio-area">
              <div class="swiper-container host-radio-sw" id="hostRadioSw02">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio01.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂1</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio02.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂2</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio03.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂3</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio04.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂4</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio05.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂5</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="host-w-icon-box">
              <div class="icon-w-arrow openHostRadio"></div>
              <div class="icon-w icon-w-thumb"></div>
              <div class="icon-w icon-w-heart"></div>
              <div class="icon-w icon-w-fb"></div>
            </div>
          </div>
          <div class="host-box">
            <div class="host-img"><img src="img/host_b03.png" alt="" /></div>
            <div class="host-cont">
              <div class="host-info">
                <div class="tit">職人藝事變</div>
                <div class="cont">
                  <div>2021/3/10</div>
                  <div>主持人：Gerger格格兒</div>
                  <div>播出時間：2400-0100</div>
                </div>
              </div>
              <div class="host-icon-wrap">
                <div class="host-num">123</div>
                <div class="radio-play-btn-box">
                  <div class="radio-play-open radioPlayOpen">
                    <img src="img/icon_radio_plus.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play01">
                    <img src="img/radio_heart.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play02">
                    <img src="img/radio_play.svg" alt="" />
                  </div>
                  <div class="radio-play-btn radio-play03 videoOpen">
                    <img src="img/radio_tv.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="host-radio-area">
              <div class="swiper-container host-radio-sw" id="hostRadioSw03">
                <div class="swiper-wrapper">
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio01.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂1</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio02.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂2</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio03.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂3</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio04.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂4</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="swiper-slide">
                    <div class="radio-cube-box">
                      <div class="radio-cube-img">
                        <img src="img/radio05.png" alt="" />
                      </div>
                      <div class="radio-cube-cont">
                        <div class="radio-cube-tit">
                          <div class="tit">豐音樂5</div>
                          <div class="icon-box">
                            <div class="icon">
                              <img src="img/icon_thumb_b.svg" alt="" />
                            </div>
                            <div class="icon">
                              <img src="img/icon_heart_r.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="radio-cube-txt">
                          <div>主持人：寶杜･巴燕</div>
                          <div>播出時間：22:00~23:00</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="host-w-icon-box">
              <div class="icon-w-arrow openHostRadio"></div>
              <div class="icon-w icon-w-thumb"></div>
              <div class="icon-w icon-w-heart"></div>
              <div class="icon-w icon-w-fb"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Swiper JS
import Swiper from "swiper";

import NavTop from "@/components/NavTop.vue";
import NavSide from "@/components/NavSide.vue";
import NavBar from "@/components/NavBar.vue";
import HostSwList from "@/components/HostSwList.vue";
import AdmArea from "@/components/AdmArea.vue";
import HostBox from "@/components/HostBox.vue";

import RadioLightBox from "@/components/RadioLightBox.vue";

import {
  apiGetCategorys,
  apiGetProgramList,
  apiGetProgramEpisodeList,
  apiGetRadioDetail,
  apiGetHostList,
} from "@/api/api.js";

export default {
  data() {
    return {
      //=== 主持人列表 ===
      hostListData: {
        index: 0,
        Keyword: "",
        IsAll: false,
        Parts: ["Alian廣播"], // 原視節目/Alian廣播/原視新聞/原視新聞族語
      },
      hostRandomPage: "",
      hostPage: "",
      hostPageTotal: "",
      hostPageList: [],
      hostPerList: [
        // { img: "r_host_01.jpg", name: "Xuzi 曾玉蘭" },
        // { img: "r_host_02.png", name: "寶杜．巴燕" },
        // { img: "r_host_03.jpg", name: "李若櫻" },
        // { img: "r_host_04.jpg", name: "Abas 潘玫伶" },
        // { img: "r_host_05.jpg", name: "Nick 杜冠頎" },
        // { img: "r_host_06.jpg", name: "甘克帆・巴宴爾" },
        // { img: "r_host_07.jpg", name: "toway 朱瑞" },
        // { img: "r_host_08.jpg", name: "Tjuku" },
      ],
      //=== 主要主持人 ===
      mainHost: {
        hostId: "",
        hostImg: "",
        name: "",
      },
      //=== 廣播節目列表 ===
      hostRadioListData: {
        index: 0,
        ProgramType: 1, //(0=原視節目, 1=廣播, 2=新聞節目or廣播or新聞)
        IsAll: false,
        HostName: "",
        HostID: [],
        CategoryID: [],
        AirDate: "",
      },
      hostRadioSwName: "hostRadioSw",
      hostAreaList: [
        // {
        //   hostImg: "host_b01.png",
        //   tit: "Kamaro'an!輕輕聽",
        //   day: "2021/3/10",
        //   hostName: "寶杜．巴燕",
        //   time: "每週一到週五晚間10時到11時",
        //   hostNum: "10",
        //   swIsShow: true,
        //   swList: [
        //     {
        //       img: "radio01.png",
        //       tit: "豐音樂1",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio02.png",
        //       tit: "豐音樂2",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio03.png",
        //       tit: "豐音樂3",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio04.png",
        //       tit: "豐音樂4",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //   ],
        // },
        // {
        //   hostImg: "host_b02.png",
        //   tit: "職人藝事變",
        //   day: "2021/3/20",
        //   hostName: "Suana 蘇瓦那",
        //   time: "每週一、二 18:00-20:00",
        //   hostNum: "20",
        //   swIsShow: true,
        //   swList: [
        //     {
        //       img: "radio01.png",
        //       tit: "豐音樂1",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio02.png",
        //       tit: "豐音樂2",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio03.png",
        //       tit: "豐音樂3",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio04.png",
        //       tit: "豐音樂4",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //   ],
        // },
        // {
        //   hostImg: "host_b03.png",
        //   tit: "青春鬥了沒",
        //   day: "2021/3/30",
        //   hostName: "甘克帆・巴宴爾",
        //   time: "每週六日 晚上21:00至22:00",
        //   hostNum: "30",
        //   swIsShow: true,
        //   swList: [
        //     {
        //       img: "radio01.png",
        //       tit: "豐音樂1",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio02.png",
        //       tit: "豐音樂2",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio03.png",
        //       tit: "豐音樂3",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //     {
        //       img: "radio04.png",
        //       tit: "豐音樂4",
        //       hostName: "寶杜･巴燕",
        //       time: "22:00~23:00",
        //     },
        //   ],
        // },
      ],
      hostRadioSwNameMore: "hostRadioSwMore",
      hostAreaMoreList: [],
    };
  },
  mounted() {
    var nowId = this.$route.params.id;
    var nowName = this.$route.query.name;
    console.log("主持人 id：", nowId);
    this.mainHost.hostId = nowId;

    this.hostRadioListData.HostID = [nowId];
    //=== 收尋當前主持人資料 ===
    this.hostListData.Keyword = nowName;
    this.getHostList(true);
    //=== 取得主持人列表 ===
    this.hostListData.Keyword = "";
    this.getHostPageList();
    // this.getHostList(false);
    //=== 取得廣播列表 === 由主持人選的話會有重複的主題 因為一個節目有三個主持人
    this.getProgramList(this.hostRadioListData, false);
    //=== 取得節目集數 ===
    this.$bus.$on("toRadioEpisodeId", (data) => {
      console.log("點擊接收節目級數ID", data);
      var { programId, moreInd } = data;
      if (moreInd == undefined) {
        //== 看是在第幾筆，要加入節目集數內容 ==
        var ind = this.hostAreaList.findIndex((item) => {
          return item.radioId == programId; // 0
        });
        console.log(ind);
        //== 看是否有集數有的話就不用再取一次 ==
        var listL = this.hostAreaList[ind].swList.length;
        if (!listL) {
          //== 取得集數列表 ==
          this.getProgramEpisodeList(programId, ind, false);
        }
      } else {
        var indMore = this.hostAreaMoreList.findIndex((item) => {
          return item.sortId == moreInd; // 0
        });
        console.log(indMore);
        //== 看是否有集數有的話就不用再取一次 ==
        var listMoreL = this.hostAreaMoreList[indMore].swList.length;
        if (!listMoreL) {
          //== 取得集數列表 == (這裡就會搜尋出在底幾筆，所以在取的集數後直接放入就可以了)
          this.getProgramEpisodeList(programId, indMore, true);
        }
      }
    });
    //=== 主持人列表頁數 ===
    this.$bus.$on("hostPageData", (data) => {
      // console.log("接收主持人列表頁數", data);
      console.log("== 多加列表 ==");
      // this.hostListData.index = data;
      console.log(this.hostPageList[data]);
      this.hostListData.index = this.hostPageList[data];
      this.getMoreHostList();
    });
    //== swiper ==
    //== hostSw ==
    // var hostSw = new Swiper("#hostSw", {
    //   slidesPerView: "auto",
    //   spaceBetween: 15,
    //   freeMode: true,
    // });
    //=== admSwiper ===
    // var admSwiper = new Swiper("#admSwiper", {
    //   slidesPerView: "auto",
    //   spaceBetween: 20,
    //   freeMode: true,
    // });
    // //=== 啟動swiper ===
    // this.$.each(this.swFunctionList, (ind, val) => {
    //   new Swiper("#" + val, {
    //     slidesPerView: "auto",
    //     spaceBetween: 15,
    //     freeMode: true,
    //   });
    // });
    // //=== 啟動後關閉 sub swiper ===
    // this.$.each(this.hostAreaList, (ind, val) => {
    //   val.swIsShow = false;
    // });
  },
  methods: {
    //=== 主持人列表 ===
    getHostPageList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("主持人列表頁數處理");
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var { Page, TotalPage } = result.data;
            console.log(result.data);
            //== 隨機初始頁碼 ==
            //因為如果只有一筆的話，random 會是0 但沒關係，因為給 0 api 會回第一頁
            //最後一筆因為不會 random 到，所以可以判斷 loading 到最後一筆時不要在call api
            var maxPage = TotalPage; //TotalPage + 1 先不加一 因為最後一頁可能只有一筆會跑不了
            console.log(maxPage);
            this.hostRandomPage = Math.floor(Math.random() * maxPage);
            //== 如果為 0 雖然會是第一頁 但是進到讀取列表內 會無法判斷到 0 第一頁不會排除掉 ==
            if (this.hostRandomPage == 0) {
              this.hostRandomPage = 1;
            }
            //== 放入所有頁碼 ==
            for (var i = 0; i < TotalPage; i++) {
              var page = i + 1;
              if (this.hostRandomPage != page) {
                this.hostPageList.push(page);
              }
            }
            this.hostPage = 0;
            this.hostPageTotal = this.hostPageList.length;
            //== 呼叫主持人隨機頁列表 ==
            this.hostListData.index = this.hostRandomPage;
            this.getHostList(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getHostList(isHost) {
      apiGetHostList(this.hostListData)
        .then((result) => {
          console.log("主持人列表");
          // console.log(JSON.parse(result.data));
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            if (isHost) {
              //== 尋找當前主持人 ==
              var { Name, Icon } = resData[0];
              this.mainHost.hostImg = Icon.ImgUrl;
              this.mainHost.name = Name;
            } else {
              // var { Page, TotalPage } = result.data;
              // console.log(Page, TotalPage);
              // this.hostPage = Page;
              // this.hostPageTotal = TotalPage;
              console.log(resData);
              var list = [];
              this.$.each(resData, (ind, val) => {
                var { ID, Name, Icon } = val;
                list.push({
                  hostId: ID.toString(),
                  img: Icon.ImgUrl,
                  name: Name,
                });
                //=== 如果為這個主持人 就帶入資料 ===
                // if (ID.toString() == this.mainHost.hostId) {
                //   this.mainHost.hostImg = Icon.ImgUrl;
                //   this.mainHost.name = Name;
                // }
              });
              this.hostPerList = list;
              //=== 補上多五筆資料 ===
              var randomHostList = this.hostPerList.slice(0, 6);
              var hasInd = randomHostList.findIndex((item) => {
                return item.hostId == this.mainHost.hostId; // 0
              });
              if (hasInd > 0) {
                //== 有跟點選重複 ==
                randomHostList.splice(hasInd, 1);
              } else {
                //== 沒有跟點選重複 ==
                randomHostList.pop();
              }
              console.log(randomHostList);
              this.$.each(randomHostList, (ind, val) => {
                var { hostId, img } = val;
                this.hostRadioListData.HostID = [hostId];
                //== 是全部並要放入主持人照片 ==
                this.getProgramList(this.hostRadioListData, true, img, ind);
              });
            }

            //=== 補上暫定的主要主持人 ===
            // this.mainHost.hostImg = this.hostPerList[0].img;
            // this.mainHost.name = this.hostPerList[0].name;
            // console.log(list[1].hostId);
            // this.hostRadioListData.HostID = [list[1].hostId];
            //== 填入主要主持人 ==
            // this.mainHost.hostId = list[1].hostId;
            // this.mainHost.name = list[1].name;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMoreHostList() {
      apiGetHostList(this.hostListData)
        .then((result) => {
          // console.log("主持人後續列表");
          // console.log(JSON.parse(result.data));
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            // var { Page, TotalPage } = result.data;
            // // console.log(Page, TotalPage);
            // this.hostPage = Page;
            // this.hostPageTotal = TotalPage;
            this.hostPage = this.hostPage + 1;
            var resData = result.data.RetData;
            // console.log(resData);
            this.$.each(resData, (ind, val) => {
              var { ID, Name, Icon } = val;
              this.hostPerList.push({
                hostId: ID.toString(),
                img: Icon.ImgUrl,
                name: Name,
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目列表ＡＰＩ === (呼叫data,是更多列表,主持人照片,更多的排序id) moreInd - 是因為要知道順序放入資料，不能依節目id，因為有可能會有重複的節目出現，所以個別給sortId
    getProgramList(apiData, isMore, hostImg, moreInd) {
      apiGetProgramList(apiData)
        .then((result) => {
          console.log("廣播節目列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            // var dataList = [];
            var { ID, Img, Title } = resData[0];
            if (!isMore) {
              this.hostAreaList.push({
                radioId: ID.toString(),
                hostImg: Img,
                tit: Title,
                day: "no-2021/3/10",
                hostName: "",
                time: "",
                hostNum: "no-10",
                swIsShow: false,
                swList: [],
              });
              // this.$.each(resData, (ind, val) => {
              //   var { ID, Img, Title } = val;
              // });
              // this.hostAreaList = dataList;
              //=== 取得廣播詳細資訊 ===
              this.getRadioDetail(ID.toString(), isMore);
              // this.$.each(this.hostAreaList, (ind, val) => {
              //   var { radioId } = val;
              //   this.getRadioDetail(radioId);
              // });
            } else {
              this.hostAreaMoreList.push({
                radioId: ID.toString(),
                sortId: moreInd,
                hostImg: hostImg,
                tit: Title,
                day: "no-2021/3/10",
                hostName: "",
                time: "",
                hostNum: "no-10",
                swIsShow: false,
                swList: [],
              });
              this.getRadioDetail(ID.toString(), isMore, moreInd);
            }
          }
          // if (resStatuse == true && resCode == 1) {
          //   //== 查無符合資料 ==
          //   this.hostAreaList = [];
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 節目詳細資料 === 因為更多列表不能用節目ID搜尋 所以要另外一個列表順序的ＩＤ尋找在第幾筆才能塞入資料
    getRadioDetail(id, isMore, moreInd) {
      apiGetRadioDetail({ ID: id })
        .then((result) => {
          console.log("廣播詳細資料");
          // console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log(resData);
            console.log("radioDetail");
            var { Host, PlayDuration, Img } = resData.Program;
            if (!isMore) {
              var indAll = this.hostAreaList.findIndex(function (item) {
                return item.radioId == id; // id 原來這麼厲害
              });
              console.log(Host);
              // this.hostAreaList[indAll].hostImg = Img;
              this.hostAreaList[indAll].hostName = Host; //Host 測試主持人
              this.hostAreaList[indAll].time = PlayDuration; //PlayDuration 每週日 13:00 ~ 14:00
            } else {
              var indMoreAll = this.hostAreaMoreList.findIndex(function (item) {
                return item.sortId == moreInd; // id 原來這麼厲害
              });
              // this.hostAreaList[indAll].hostImg = Img;
              this.hostAreaMoreList[indMoreAll].hostName = Host; //Host 測試主持人
              this.hostAreaMoreList[indMoreAll].time = PlayDuration; //PlayDuration 每週日 13:00 ~ 14:00
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //=== 取得節目集數 ===
    getProgramEpisodeList(id, listind, isMore) {
      apiGetProgramEpisodeList({
        index: 0,
        ProgramType: 1,
        ScheduleID: id,
        IsAll: false,
      })
        .then((result) => {
          console.log("節目級數列表");
          console.log(result.data);
          var resStatuse = result.data.success;
          var resCode = result.data.errorcode;
          if (resStatuse == true && resCode == 0) {
            var resData = result.data.RetData;
            console.log('內容：',resData)
            if (!isMore) {
              //== 取得節目所有集數後，放入sw列表  ==
              this.$.each(resData, (ind, val) => {
                var { ID, Episode, Title, Image, m3u8Url } = val;
                this.hostAreaList[listind].swList.push({
                  id: ID.toString(),
                  img: Image, //this.hostAreaList[listind].hostImg
                  //"http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg"
                  tit: Title,
                  videoUrl: m3u8Url,
                  hostName: "no 寶杜･巴燕",
                  time: "no 22:00~23:00",
                });
              });
            } else {
              this.$.each(resData, (ind, val) => {
                var { ID, Episode, Title, Image, m3u8Url } = val;
                this.hostAreaMoreList[listind].swList.push({
                  id: ID.toString(),
                  img: Image, //this.hostAreaList[listind].hostImg
                  //"http://ipcfcms.ipcf.org.tw/upload/convert_video_thumbnail/202107/16255454420521/16255454420521.jpg"
                  tit: Title,
                  videoUrl: m3u8Url,
                  hostName: "no 寶杜･巴燕",
                  time: "no 22:00~23:00",
                });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    //=== 抓取需要跑 swiper 的 Id ===
    // swFunctionList() {
    //   var list = [];
    //   this.$.each(this.hostAreaList, (ind, val) => {
    //     list.push(this.hostRadioSwName + (ind + 1));
    //   });
    //   return list;
    // },
  },
  watch: {
    $route() {
      var nowActHost = this.$route.params.id; //狀態
      this.hostRadioListData.HostID = [nowActHost];
      //
      this.mainHost.hostId = nowActHost;
      var actInd = this.hostPerList.findIndex(function (item) {
        return item.hostId == nowActHost; // 0
      });
      this.mainHost.hostImg = this.hostPerList[actInd].img;
      this.mainHost.name = this.hostPerList[actInd].name;
      //=== 取得廣播列表 ===
      this.getProgramList(this.hostRadioListData, false);
    },
  },
  //== 元件銷毀前要取消監聽 ==
  beforeDestroy() {
    this.$bus.$off("toRadioEpisodeId");
    this.$bus.$off("hostPageData");
  },
  components: {
    NavTop,
    NavSide,
    NavBar,
    HostSwList,
    AdmArea,
    HostBox,
    RadioLightBox,
  },
};
</script>