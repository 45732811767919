<template>
  <div class="radio-list-item" :id="hostRadioSwName + (ind + 1)">
    <div class="radio-item-group" v-for="(item, index) in swList" :key="index">
      <div class="radio-item-tit">
        {{ item.tit }}
      </div>
      <!-- 帶節目時間 <div class="radio-item-time">21:00～22:00</div> -->
      <div class="radio-ui">
        <div class="radio-icon-play" v-if="item.videoUrl" @click="getRadio(item.videoUrl)">
          <img src="@/assets/img/radio_play.svg" alt="" />
        </div>
        <div class="radio-icon-video" v-if="item.youtube">
          <img src="@/assets/img/radio_tv.svg"  alt="" />
        </div>
      </div>
    </div>
  </div>
  <!-- 舊版集數列表 
    <div class="swiper-container host-radio-sw" :id="hostRadioSwName + (ind + 1)">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, index) in swList" :key="index">
        <div class="radio-cube-box" @click="getRadio(item.videoUrl)">
          <div class="radio-cube-img">
            <img :src="item.img" alt="" />
            //<img :src="require('@/assets/img/' + item.img)" alt="" />
          </div>
          <div class="radio-cube-cont">
            <div class="radio-cube-tit">
              <div class="tit">{{ item.tit }}</div>
              <div class="icon-box">
                //<div class="icon">
                //  <img src="@/assets/img/icon_thumb_b.svg" alt="" />
                //</div>
                <div class="icon">
                  <img src="@/assets/img/icon_heart_r.svg" alt="" />
                </div>
              </div>
            </div>
            //<div class="radio-cube-txt">
            //  <div>主持人：{{ item.hostName }}</div>
            //  <div>播出時間：{{ item.time }}</div>
            //</div>
          </div>
        </div>
      </div>
    </div>
  </div> 
  -->
</template>




<script>
// import Swiper JS
import Swiper from "swiper";

import { apiGetRadioEpisode } from "@/api/api.js";

export default {
  name: "HostBoxSw",
  props: ["ind", "hostRadioSwName", "swList"],
  data() {
    return {
      swCont: "",
      radioUrl: "",
    };
  },
  mounted() {
    console.log("=== HostBoxSw mounted ===");
    var swName = this.hostRadioSwName + (this.ind + 1);
    this.swCont = new Swiper("#" + swName, {
      slidesPerView: "auto",
      spaceBetween: 15,
      freeMode: true,
    });
  },
  methods: {
    //=== 列表就帶來了 所以直接call ===
    getRadio(url) {
      if (url != null) {
        this.$bus.$emit("swOpenRadio", url);
      } else {
        alert("目前沒有廣播檔案");
      }
    },
    // getRadio(id) {
    //   if (this.radioUrl == "") {
    //     this.getRadioEpisode(id);
    //   } else {
    //     this.$bus.$emit("swOpenVideo", this.radioUrl);
    //   }
    // },
    //=== 取得單集內容 ===
    // getRadioEpisode(id) {
    //   apiGetRadioEpisode({ ID: id })
    //     .then((result) => {
    //       console.log("Radio '單集'詳細資料 取得！");
    //       console.log(result.data);
    //       var resStatuse = result.data.success;
    //       var resCode = result.data.errorcode;
    //       if (resStatuse == true && resCode == 0) {
    //         var videoLink = result.data.RetData.m3u8_url;
    //         var { Title, VideoID, PlayStartTime, PlayEndTime } =
    //           result.data.RetData.Episode;
    //         console.log(Title, VideoID, PlayStartTime, PlayEndTime);
    //         if (videoLink != null) {
    //           this.radioUrl == videoLink;
    //           this.$bus.$emit("swOpenVideo", videoLink);
    //         } else {
    //           console.log("沒有影音檔案");
    //           alert("目前沒有影音檔案");
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
};
</script>