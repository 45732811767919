<template>
  <transition name="fade">
    <div
      class="light-video-area radio-fixed"
      id="lightVideoArea"
      v-show="lgRadioShow"
    >
      <div class="light-video-wrap">
        <div class="light-video-close">
          <div class="icon" id="lightVideoClose" @click="closeRadio">
            <!-- @click="lgVideoShow = !lgVideoShow" -->
            <img src="@/assets/img/icon_m_close_b.svg" alt="" />
          </div>
        </div>
        <div class="light-video-box" id="lightRadioBox">
          <!-- <video class="video-js vjs-theme-forest video-banner" id="lgRadio">
            controls="controls"
            preload="auto"
            poster="@/assets/img/video_default.png"
            data-setup="{}"
            <source :src="videoSrc" :type="videoType" />
            <source src="@/assets/video/adv.mp4" type="video/mp4" />
            <source src="video/adv.mp4" type="video/mp4"/>
            <source src="video/adv.webm" type="video/webm"/>
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that<a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
                >supports HTML5 video</a
              >
            </p>
          </video> -->
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "RadioLightBox",
  // props: ["show", "videoLink"],
  data() {
    return {
      lgRadioShow: false,
      videoSrc:
        "https://streamipcfapp.akamaized.net/live/_definst_/smil:liveabr.smil/playlist.m3u8",
      // videoSrc: "https://youtu.be/87OMBCgaZDk",
      // videoSrc: "",
      // videoType: "application/x-mpegURL",//要對應影片的格式，這個是live m3u8用
      // videoType: "video/webm",
      // videoTyps: "video/mp4",
      videoType: "application/x-mpegURL",
    };
  },
  mounted() {
    //== 設置videojs ==
    // window.videojs("lgRadio", {
    //   controls: true,
    //   autoplay: false,
    //   preload: "auto",
    //   poster: require("@/assets/img/video_default.png"),
    // });
    //=== 接收影片打開 ===
    this.$bus.$on("swOpenRadio", (data) => {
      //有開起來就先關掉
      if (this.lgRadioShow) {
        this.closeRadio();
      }
      console.log(data);

      this.lgRadioShow = true;
      //== 如果只送link近來 ==
      data.swType == undefined
        ? (this.videoSrc = data)
        : (this.videoSrc = data.videoLink);

      // this.videoSrc = require("@/assets/video/adv.mp4");
      // this.videoType = "video/mp4";//要注意影片內容Type
      //== 關閉時 dispose 是移除整個dom元件 所以打開時要再加入dom 在宣告使用 ==
      //data-setup=\'{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "' +this.videoSrc +'"}],"youtube": { "ytControls": 1 ,"autoplay": 1 }}\'>'
      var liveVideo =
        '<audio autoplay preload="auto" class="video-js vjs-theme-forest video-banner radio-banner" id="lgRadio" data-setup={}>' +
        '<source src="' +
        this.videoSrc +
        '" type="' +
        this.videoType +
        '" />' +
        '<p class="vjs-no-js"> To view this video please enable JavaScript, and consider upgrading to a web browser that' +
        '<a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>' +
        "</p></audio>";

      this.$("#lightRadioBox").append(liveVideo);

      //== 設置videojs ==
      window.videojs("lgRadio", {
        controls: true, //如果自動播放，就不用 true , false 就好
        autoplay: true, //點擊後直接播放 false
        preload: "auto",
        // poster: require("@/assets/img/video_default.png"),
      });

      console.log("廣播連結是：", this.videoSrc);

      //== 改變影片網址 == * 如果是是直接更換可以這樣直接更換
      //window.videojs("lgRadio").src({ type: this.videoType, src: this.videoSrc });

      //   var delId = this.list.findIndex((item) => {
      //     return item.subcategory_id == data; // 0
      //   });
      //   this.list.splice(delId, 1);
    });
    //=== live 頁面因為要會一起播 如果有打開，要關掉 ===
    this.$bus.$on("swOpenRadioClose", (data) => {
      if (data && this.lgRadioShow) {
        this.closeRadio();
      }
    });
  },
  methods: {
    //=== 關閉lightbox ===
    closeRadio() {
      console.log("自己關關");
      this.lgRadioShow = false;
      //== 關閉的時候也要取消video，dispose 是移除整個dom元件 所以打開時要再加入dom==
      window.videojs("lgRadio").dispose();
      // this.$bus.$emit("closeVideo", "close");
    },
  },
  beforeDestroy() {
    console.log("離開lightRadioBox");
    //== 如果再打開的情況離開，要移除 ==
    if (this.lgRadioShow) {
      window.videojs("lgRadio").dispose();
    }
    //==== 元件銷毀前要取消監聽 ====
    this.$bus.$off("swOpenRadio");
    this.$bus.$off("swOpenRadioClose");
    // this.$bus.$off("closeVideo");
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* 自定義bar */
.radio-fixed {
  width: auto;
  background: none !important;
  height: 50px;
  position: fixed;
  top: auto;
  left: 11px;
  right: 11px;
  bottom: 70px;
  z-index: 2;
}
.radio-fixed .light-video-area {
  background: none;
}
.radio-fixed .light-video-wrap {
  width: 100%;
  position: static;
  transform: translate(0, 0);
  height: 50px;
}
.radio-fixed .light-video-close {
  width: 28px;
  padding: 0;
  margin: 0;
  top: 11px;
  right: 14px;
  position: absolute;
  z-index: 1;
}
.radio-fixed .light-video-close .icon {
  width: 100%;
}
.radio-fixed .light-video-close .icon img {
  width: 100%;
}

.radio-fixed .light-video-box .radio-banner {
  width: 100%;
  height: 50px;
  background: none;
  border-radius: 40px;
  overflow: hidden;
  margin: 0 auto;
}
.radio-fixed .radio-banner .video-js {
  background: none;
}
.radio-fixed .light-video-box .radio-banner .vjs-fullscreen-control {
  display: none;
}
.radio-fixed .vjs-has-started .vjs-control-bar {
  color: #1e242c;
  height: 50px;
  padding: 0 50px 0 0;
  background: rgb(255 255 255 / 90%);
}
.radio-fixed .light-video-box .video-js .vjs-play-control {
  width: 80px;
}
.radio-fixed .light-video-box .vjs-button > .vjs-icon-placeholder:before {
  font-size: 3em;
  line-height: 50px;
}
.radio-fixed .light-video-box .video-js .vjs-time-control {
  font-size: 1.7em;
}
.radio-fixed .light-video-box .vjs-big-play-button{ display:none !important;}
.radio-fixed .light-video-box .vjs-loading-spinner{ display:none !important;}
</style>